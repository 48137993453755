import React from 'react'
import { Container } from 'react-bootstrap'
import './V4V.css'

export default function V4V(props) {

    const handleContentChange = (content) => {
		props.setShowContent(content);
		window.scrollTo({ top: 0, behavior: 'smooth'})
	}
    return (
        <Container className='v4v'>
            <h2 className='text-danger'>Value for Value</h2>
            <hr />
            <h4>
                &ldquo;Stay Awhile&rdquo; is a value-for-value rock album that is published digitally using the Podcasting 2.0 value infrastructure.
            </h4>
            <hr />
            <p>
                In the current paradigm of music publication, artists submit to streaming services to “monetize” their music, and the payout amounts to fractions of a cent per stream. Their music is purposfully under-valued by these corporations so they can pay artists as little as possible. It is our view that, through the power of the internet, the small and independent artist finally has access to terrible, one-sided airplay deals which used to be reserved for superstars. If the streaming corporations have their way, nothing will change.
            </p>
            <p>
                 The <a href="https://podcastindex.org" target="_blank" rel="noreferrer">Podcast Index</a> and its Podcasting 2.0 standard was launched in the fall of 2020 to &ldquo;preserve, protect and extend the open, independent podcasting ecosystem&rdquo; and to &ldquo;re-tool podcasting to a platform of value exchange that includes developers with podcasters and listeners.&rdquo; A side benefit is that this standard is useful for much more than podcasting. Films, audiobooks and now music may be &ldquo;value-enabled&rdquo; and published indepdently using the same system.
            </p>
            <p>
                As Able and the Wolf, we wanted to take this extraordinary vision and expand it to the world of music publishing. Everything the Podcast Index has achieved for podcasting can be brought to musicians and artists as well. Together we can collaborate within a censorship-resistant protocol that combines publishing digital media assets online via an RSS feed with real-time micropayments over the <a href="https://lightning.network/" target="_blank" rel="noreferrer">Bitcoin Lightning network</a>.
            </p>
            <p>
                Currently, &ldquo;Stay Awhile&rdquo; can be listened to in any podcasting app free of charge. The value proposition here is a &ldquo;Play to Pay&rdquo; concept. We invite you to listen to the tracks, and if you find value in the project, you can return that value to us directly in the form of streaming Satoshis, boosting, or even attaching a message to your boost in the form of a &ldquo;boostagram.&rdquo; For a full list of apps that support the value tag on your platform of choice, check out our &ldquo;<button id="noStyle" className='text-primary' onClick={() => handleContentChange('listen')}>Where to Listen</button>&rdquo; page.
            </p>
            <p>
                When you stream and boost value to &ldquo;Stay Awhile,&rdquo; you are not only supporting Able and the Wolf directly. You are also supporting the greater ecosystem that works behind the scenes to bring you this album. A portion of your boost is given to the artist who created the cover art for the track you are boosting, and another portion is also sent to the developers of the podcasting app you use and to the Podcast Index itself.
            </p>
            <p>
                Our vision for the future is to encourage more musicians to publish their works under this digital &ldquo;value-for-value&rdquo; model. We also envision app developers will soon be building solutions for a great music-oriented user experience in apps specifically designed for music listening, leveraging the <a href="https://github.com/Podcastindex-org/podcast-namespace/blob/main/docs/1.0.md#user-content-medium" target="_blank" rel="noreferrer">&lt;podcast:medium&gt;</a> tag to discover RSS feeds that contain music and serve them to their users. 
            </p>
        </Container>
    )
}
