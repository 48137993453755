import React from 'react'
import { Container } from 'react-bootstrap'
import './Home.css'

export default function Home() {
    return (
        <section className='home py-3'>
            <h1 className='text-danger'>Stay Awhile</h1>
            <br />
            <Container>
                <h2>Able and the Wolf</h2>
                <h4 className='text-danger'>Christmas Eve, 2021</h4>
            </Container>
        </section>
    )
}
