import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import './About.css'

import ableAvi from '../../images/ablekirby.jpg'
import spencerAvi from '../../images/spencer.jpg'

export default function About() {
    return (
        <Container fluid className='about'>
            <Row>
                <Col>
                    <h2 className='text-danger'>About the Band</h2>
                </Col>
            </Row>
            <hr />
            <br />
            <Row className='band-bios'>
                <Col md={{span: 3, offset:1}} className='align-self-center'>
                    <Image src={ableAvi} roundedCircle /> 
                </Col>
                <Col md={{span: 6, offset: 1}} className='align-self-center'>
                    <h4>Able Kirby</h4>
                    <p>Able Kirby is a cat herder, consultant, co-host and mediocre programmer. You can find him most Wednesday evenings enriching the internet with his co-host coldacid on the <a href='https://rareencounter.net' target='_blank' rel='noreferrer'>Rare Encounter podcast</a>. A long-time producer of the No Agenda show, Able enjoys writing and performing music, creating and consuming podcasts, and boosting value-for-value content deeper into the 21st century. You can find more at <a href='https://ablekirby.com' target='_blank' rel='noreferrer'>AbleKirby.com</a></p>
                </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row id='flex' className='band-bios'>
                <Col md={{span: 6, offset: 1}} id='SpencerBio' className='align-self-center'>
                    <h4>Sir Spencer, Wolf of KC</h4>
                    <p>Sir Spencer is a drummer, podcaster, web developer, Real Estate salesperson, bartender, licensed drone pilot, dog herder and father of three human resources (thus far). You can find him on Tuesday nights with his wife Dame DuhLaurien on the <a href='https://bowlafterbowl.com' target='_blank' rel='noreferrer'>Bowl After Bowl podcast</a>. When he's not juggling a half-dozen gig jobs, Spencer is probably either listening to the No Agenda show, organizing the next KC No Agenda Meetup, or working on any number of passion projects, including maintaining this website.</p>
                </Col>
                <Col md={{span: 3, offset:1}} id='SpencerImg' className='align-self-center'>
                    <Image src={spencerAvi} roundedCircle /> 
                </Col>
            </Row>
        </Container>
    )
}