import React from "react"
import "./Album.css"
import { Carousel, Container } from "react-bootstrap"
import MakinBeansArt from "../../images/01_MakinBeans.jpg"
import WillowBrownArt from "../../images/02_WillowBrown.jpg"
import LikeWineArt from "../../images/03_LikeWine.jpg"
import StayAwhileArt from "../../images/04_StayAwhile.jpg"
import MapsOfHomeArt from "../../images/05_MapsOfHome.jpg"
import ChristmasArt from "../../images/06_OneChristmas.jpg"
import StayAwhileRepriseArt from "../../images/07_StayAwhileReprise.jpg"

export default function Album() {

	return (
		<Container id="albumContainer">
			<Carousel>
				<Carousel.Item>
					<img className='d-block w-100' src={MakinBeansArt} alt='Makin Beans artwork' />
						<Carousel.Caption>
							<h3>Makin' Beans</h3>
							<p>
								Artwork by <a href='https://rodgerroundy.com/' target='_blank' rel='noreferrer'>Rodger Roundy</a>
							</p>
						</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img className='d-block w-100' src={WillowBrownArt} alt='Willow Brown artwork' />
					<Carousel.Caption>
						<h3>Willow Brown</h3>
						<p>
							Artwork by <a href='https://deanreiner.com/' target='_blank' rel='noreferrer'>Dean Reiner</a>
						</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img className='d-block w-100' src={LikeWineArt} alt='Like Wine artwork' />
					<Carousel.Caption>
						<h3>Like Wine</h3>
						<p>
							Artwork by <a href='https://behindthesch3m3s.com/' target='_blank' rel='noreferrer'>Boo-bury</a>
						</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img className='d-block w-100' src={StayAwhileArt} alt='Stay Awhile artwork' />
					<Carousel.Caption>
						<h3>Stay Awhile</h3>
						<p>
							Artwork by <a href='https://bowlafterbowl.com/' target='_blank' rel='noreferrer'>Laurien Rose</a>
						</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img className='d-block w-100' src={MapsOfHomeArt} alt='Maps of Home artwork' />
					<Carousel.Caption>
						<h3>Maps of Home</h3>
						<p>
							Artwork by <a href='https://hogstory.com/' target='_blank' rel='noreferrer'>Carolyn Blaney</a>
						</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img className='d-block w-100' src={ChristmasArt} alt='Christmas artwork' />
					<Carousel.Caption>
						<h3>One Christmas</h3>
						<p>
							Artwork by <a href='https://rodgerroundy.com/' target='_blank' rel='noreferrer'>cottongin</a>
						</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img className='d-block w-100' src={StayAwhileRepriseArt} alt='Stay Awhile (reprise) artwork' />
					<Carousel.Caption>
						<h3>Stay Awhile (Reprise)</h3>
						<p>
							Artwork by <a href='https://bowlafterbowl.com/' target='_blank' rel='noreferrer'>Laurien Rose</a>
						</p>
					</Carousel.Caption>
				</Carousel.Item>
			</Carousel>
		</Container>
	)
}
