import React from 'react'
import './Header.css'

export default function Footer() {
    
    return (
        <footer className='footer text-center bg-primary text-light'>
            <p>
                <br />
                <em>&copy; {new Date().getFullYear()} Able and the Wolf&ensp; &#8212; &ensp;All Rights Reserved</em>
            </p>
        </footer>
    )
}
