import React from 'react'
import './Bump.css'

export default function HeaderBump() {
    return (
        <div className='bump-me'>
            
        </div>
    )
}
