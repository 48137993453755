import React from "react"
import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css"
import Album from "./Components/Album/Album"
import About from "./Components/About/About"
import Footer from "./Components/Footer"
import Header from "./Components/Header"
import Home from "./Components/Home/Home"
import V4V from "./Components/V4V/V4V"
import HeaderBump from "./Components/HeaderBump"
import Listen from "./Components/Listen/Listen"
import NotFound from "./Components/NotFound"

import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

function App() {

  return (
    <div className="App">
      <Router>
        <Header />
        <HeaderBump />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/album" element={<Album />} />
          <Route path="/v4v" element={<V4V />} />
          <Route path="/listen" element={<Listen />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      {/* {showContent === 'Home' &&
        <Home />
      }
      {showContent === 'About' &&
        <About />
      }
      {showContent === 'Album' &&
        <Album />
      }
      {showContent === 'v4v' &&
        <V4V setShowContent={setShowContent} />
      }
      {showContent === 'listen' &&
        <Listen />
      } */}
      <Footer />
    </div>
  )
}

export default App
