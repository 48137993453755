import React from "react"
import "./NotFound.css"
import image from "../images/Lost_Wolf.jpg"

export default function NotFound() {

  return (
    <div className="notFound">
      <img src={image} alt="Lost wolf 404 not found" />
      <h1>Awwww HECK!</h1>
      <p>
        You must be lost fren...
        <br />
        that resource was not found!
      </p>
      <a href="/" className="btn btn-color">
        Return Home
      </a>
    </div>
  )
}
