import React from 'react'
import { Accordion, Container, Row, Col, Image, Table } from 'react-bootstrap'
import './Listen.css'

//images for apps
import podfriendLogo from '../../images/podfriend.jpg'
import podStationLogo from '../../images/podStation.jpg'
import mpMoneyLogo from '../../images/mpmoney.jpg'
import curiocasterLogo from '../../images/curiocaster.png'
import fountainLogo from '../../images/fountain.png'
import breezeLogo from '../../images/breez.png'
import sphinxLogo from '../../images/sphinxchat.png'
import zionLogo from '../../images/zion.png'
import castamaticLogo from '../../images/castamatic.png'

export default function Listen() {
    return (
        <Container className='listen'>
            <h1 className='text-danger'>Where to Listen</h1>
            <hr />
            <h4>To listen to &ldquo;Stay Awhile&rdquo; in a value-enabled app, please select your platform of choice below:</h4>
            <hr />
            <Row>
                <Col md={{span: 10, offset: 1}} lg={{span: 8, offset: 2}}>
                    <Accordion>
                        <Accordion.Item eventKey='0'>
                            <Accordion.Header>
                                Web apps (cross-platform)
                            </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    {/* <thead>
                                        <tr>
                                            <th>Logo</th>
                                            <th>App Homepage</th>
                                            <th>Stay Awhile</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <td><Image rounded src={curiocasterLogo} alt='CurioCaster logo' /></td>
                                            <td>
                                                <a href="https://curiocaster.com/" target="_blank" rel='noreferrer'>CurioCaster</a>
                                                <p>Web-based podcast app</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={podfriendLogo} alt='Podfriend logo' /></td>
                                            <td>
                                                <a href="https://podfriend.com/" target="_blank" rel='noreferrer'>Podfriend</a>
                                                <p>Web-based podcast app</p>
                                            </td>
                                        </tr>                                        
                                        <tr>
                                            <td><Image rounded src={podStationLogo} alt='podStation logo' /></td>
                                            <td>
                                                <a href='https://chrome.google.com/webstore/detail/podstation-podcast-player/bpcagekijmfcocgjlnnhpdogbplajjfn' target='_blank' rel='noreferrer'>podStation</a>
                                                <p>Chrome browser extension</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={mpMoneyLogo} alt='mp.money logo' /></td>
                                            <td>
                                                <a href='https://cryptostream.online/' target='_blank' rel='noreferrer'>mp.money</a>
                                                <p>Web-based podcast app</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='1'>
                            <Accordion.Header>
                                Mobile: Android
                            </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    {/* <thead>
                                        <tr>
                                            <th>Logo</th>
                                            <th>App Homepage</th>
                                            <th>Stay Awhile</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <td><Image rounded src={breezeLogo} alt='Breez logo' /></td>
                                            <td>
                                                <a href="https://breez.technology/" target="_blank" rel='noreferrer'>Breez</a>
                                                <p>Mobile lightning wallet and podcast app</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={fountainLogo} alt='Fountain logo' /></td>
                                            <td>
                                                <a href='https://www.fountain.fm/' target='_blank' rel='noreferrer'>Fountain</a>
                                                <p>Mobile podcast app with clip-sharing feature</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={podfriendLogo} alt='Podfriend logo' /></td>
                                            <td>
                                                <a href='https://www.podfriend.com/' target='_blank' rel='noreferrer'>Podfriend</a>
                                                <p>Mobile podcast app (available as APK)</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={sphinxLogo} alt='Sphinx.chat logo' /></td>
                                            <td>
                                                <a href='https://sphinx.chat/' target='_blank' rel='noreferrer'>Sphinx.chat</a>
                                                <p>Encrypted messaging app with podcast player</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={zionLogo} alt='Zion logo' /></td>
                                            <td>
                                                <a href='https://getzion.com/' target='_blank' rel='noreferrer'>Zion</a>
                                                <p>Social media platform with podcast integration</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='2'>
                            <Accordion.Header>
                                Mobile: Apple iOS
                            </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    {/* <thead>
                                        <tr>
                                            <th>Logo</th>
                                            <th>App Homepage</th>
                                            <th>Stay Awhile</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <td><Image rounded src={breezeLogo} alt='Breez logo' /></td>
                                            <td>
                                                <a href="https://breez.technology/" target="_blank" rel='noreferrer'>Breez</a>
                                                <p>Mobile lightning wallet and podcast app via TestFlight (beta)</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={castamaticLogo} alt='Castamatic logo' /></td>
                                            <td>
                                                <a href="https://castamatic.com/beta" target="_blank" rel='noreferrer'>Castamatic</a>
                                                <p>Mobile podcast app via TestFlight (beta)</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={fountainLogo} alt='Fountain logo' /></td>
                                            <td>
                                                <a href='https://www.fountain.fm/' target='_blank' rel='noreferrer'>Fountain</a>
                                                <p>Mobile podcast app with clip-sharing feature</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={podfriendLogo} alt='Podfriend logo' /></td>
                                            <td>
                                                <a href='https://www.podfriend.com/' target='_blank' rel='noreferrer'>Podfriend</a>
                                                <p>Mobile podcast app via TestFlight (beta)</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={sphinxLogo} alt='Sphinx.chat logo' /></td>
                                            <td>
                                                <a href='https://sphinx.chat/' target='_blank' rel='noreferrer'>Sphinx.chat</a>
                                                <p>Encrypted messaging app with podcast player</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={zionLogo} alt='Zion logo' /></td>
                                            <td>
                                                <a href='https://getzion.com/' target='_blank' rel='noreferrer'>Zion</a>
                                                <p>Social media platform with podcast integration</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='3'>
                            <Accordion.Header>
                                Desktop: Mac OS
                            </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    {/* <thead>
                                        <tr>
                                            <th>Logo</th>
                                            <th>App Homepage</th>
                                            <th>Stay Awhile</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <td><Image rounded src={podfriendLogo} alt='Podfriend logo' /></td>
                                            <td>
                                                <a href="https://podfriend.com/" target="_blank" rel='noreferrer'>Podfriend</a>
                                                <p>Desktop podcasting app</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={sphinxLogo} alt='Sphinx.chat logo' /></td>
                                            <td>
                                                <a href='https://sphinx.chat/' target='_blank' rel='noreferrer'>Sphinx.chat</a>
                                                <p>Encrypted messaging app with podcast player</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={zionLogo} alt='Zion logo' /></td>
                                            <td>
                                                <a href='https://getzion.com/' target='_blank' rel='noreferrer'>Zion</a>
                                                <p>Social media platform with podcast integration</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='4'>
                            <Accordion.Header>
                                Desktop: Windows
                            </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    {/* <thead>
                                        <tr>
                                            <th>Logo</th>
                                            <th>App Homepage</th>
                                            <th>Stay Awhile</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <td><Image rounded src={podfriendLogo} alt='Podfriend logo' /></td>
                                            <td>
                                                <a href="https://podfriend.com/" target="_blank" rel='noreferrer'>Podfriend</a>
                                                <p>Desktop podcasting app</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={sphinxLogo} alt='Sphinx.chat logo' /></td>
                                            <td>
                                                <a href='https://sphinx.chat/' target='_blank' rel='noreferrer'>Sphinx.chat</a>
                                                <p>Encrypted messaging app with podcast player</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={zionLogo} alt='Zion logo' /></td>
                                            <td>
                                                <a href='https://getzion.com/' target='_blank' rel='noreferrer'>Zion</a>
                                                <p>Social media platform with podcast integration</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey='5'>
                            <Accordion.Header>
                                Desktop: Linux
                            </Accordion.Header>
                            <Accordion.Body>
                                <Table striped bordered hover>
                                    {/* <thead>
                                        <tr>
                                            <th>Logo</th>
                                            <th>App Homepage</th>
                                            <th>Stay Awhile</th>
                                        </tr>
                                    </thead> */}
                                    <tbody>
                                        <tr>
                                            <td><Image rounded src={sphinxLogo} alt='Sphinx.chat logo' /></td>
                                            <td>
                                                <a href='https://sphinx.chat/' target='_blank' rel='noreferrer'>Sphinx.chat</a>
                                                <p>Encrypted messaging app with podcast player</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Image rounded src={zionLogo} alt='Zion logo' /></td>
                                            <td>
                                                <a href='https://getzion.com/' target='_blank' rel='noreferrer'>Zion</a>
                                                <p>Social media platform with podcast integration</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
        </Container>
    )
}
