import React, {useState} from 'react'
// import logo from '../images/astroboy-square.jpg'
import {Navbar, Nav, Image} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './Header.css'
import logo from '../images/ablewolf_smol.png'

export default function Header(props) {
	const [expanded, setExpanded] = useState(false);


    return (
        <Navbar variant='dark' bg='primary' fixed="top" expand='md' expanded={expanded}>
			<Navbar.Brand href='/'>&emsp;
			     <Image rounded fluid src={logo} alt="Able and the Wolf logo"/>
				<span className='able'>Able</span>
				<span className='and-the'>kt</span>
				<span className='wolf'>Wolf</span>
			</Navbar.Brand>
			<Navbar.Toggle onClick={() => expanded === false ? setExpanded(true) : setExpanded(false)}/>
			<Navbar.Collapse className='justify-content-end'>
				<Navbar.Text>
					<Nav className='mr-auto'>
						<Nav.Link href='/about'>About</Nav.Link>
						<Nav.Link href='/album'>Album</Nav.Link>
						<Nav.Link href='/v4v'>Value for Value</Nav.Link>
						<Nav.Link href='/listen'>Listen</Nav.Link>
						{/* <Nav.Link onClick={() => handleContentChange('About')}>About</Nav.Link>
						<Nav.Link onClick={() => handleContentChange('Album')}>Album Art</Nav.Link>
						<Nav.Link onClick={() => handleContentChange('v4v')}>Value for Value</Nav.Link>
						<Nav.Link onClick={() => handleContentChange('listen')}>Listen</Nav.Link> */}
						<Nav.Link href="https://podcastindex.org/podcast/4088038" target="_blank" rel="noreferrer">AbleKraft</Nav.Link>
						<Nav.Link href="https://ableandthewolf.com/static/media/feed.xml" target="_blank" rel="noreferrer">RSS Feed</Nav.Link>
					</Nav>
				</Navbar.Text>
			</Navbar.Collapse>
		</Navbar>
    )
}
